<template>
    <div v-if="sign_stat>=0" class="signPlan">
        <div class="group-button flex">
            <div v-if="sign_stat>=0 && sign_stat<3" style="min-width:170px;width:calc(100% / 6);position:absolute;left:0;">
                <el-button @click="lookContract(item)" size="small" type="primary">查看合同</el-button>
                <el-button @click="downloadContract(item)" size="small" type="primary">下载合同</el-button>
            </div>
            <div v-if="sign_stat==1" style="width:calc(100% / 6);position:absolute;left:calc(100% / 6);">
                <el-button @click="gotoSign(item)" size="small" type="primary">待签约</el-button>
            </div>
            <!-- 版权购买 -->
            <div v-if="sign_stat>=3 && sign_stat<21"
                style="min-width:170px;width: calc(100% / 6);position:absolute;left:calc(100% / 6 * 2);">
                <el-button @click="lookContract(item)" size="small" type="primary">查看合同</el-button>
                <el-button @click="downloadContract(item)" size="small" type="primary">下载合同</el-button>
            </div>
            <!-- 需求 -->
            <div v-if="sign_stat==3 && item.tradeOrder && item.tradeOrder.paidAmount>0" 
                style="min-width:170px;width: calc(100% / 6);position:absolute;left:calc(100% / 6 * 3);">
                <el-button @click="view_pay(item)" size="small" type="primary" plain>确认收款</el-button>
                <el-button @click="view_pay(item)" size="small" type="primary">收款查询</el-button>
            </div>
            <!-- 需求 -->
            <div v-if="sign_stat==4 && item.tradeOrder && item.tradeOrder.paidAmount>0" 
                style="min-width:170px;width: calc(100% / 6);position:absolute;left:calc(100% / 6 * 4);">
                <el-button @click="view_pay(item)" size="small" type="primary" plain>确认收款</el-button>
                <el-button @click="view_pay(item)" size="small" type="primary">收款查询</el-button>
            </div>
            <div v-if="sign_stat==6" style="width:calc(100% / 6);position:absolute;left:calc(100% / 6 * 4);">
                <el-button @click="view_pay(item)" size="small" type="primary">收款查询</el-button>
            </div>
        </div>
        <div class="item-plan">
            <el-steps :active="sign_stat" align-center>
                <el-step title="对方已签约" icon="iconfont icon-dggfg1"></el-step>
                <el-step title="等待我方签约" icon="iconfont icon-dggfg1"></el-step>
                <el-step title="双方已签约" icon="iconfont icon-dggfg1"></el-step>
                <el-step title="等待对方付款" icon="iconfont icon-dggfg1"></el-step>
                <el-step title="等待收款确认" icon="iconfont icon-dggfg1"></el-step>
                <el-step title="交易完成" icon="iconfont icon-dggfg1"></el-step>
            </el-steps>
        </div>

        <el-dialog @close="refresh" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <div v-if="popTitle == '签约'">
                <Sign :popTitle="popTitle" @data="refresh" @signState="signState" @hide="hide"></Sign>
            </div>
            <div v-if="popTitle == '遇到问题'">
                <SignBack :popTitle="popTitle" @hide="popVisible=false"></SignBack>
            </div>
            <div v-if="popTitle == '请选择签约人'">
                <SignSelect :popTitle="popTitle" :user_id="seal_user_id" :sealMember="sealMember" @hide="popVisible=false" @success="buy"></SignSelect>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import {
  listSealMember,
  contractAddr,
  contractView,
  contractDownload
} from "@/service/contract";
import { getPayId } from "@/service/pay";
import Sign from "@/components/sign/sign.vue";
import SignBack from "@/components/sign/signBack.vue";
import SignSelect from '@/components/sign/SignSelect.vue';
export default {
  components: {
    Sign,
    SignBack,
    SignSelect
  },
  props: ["item", "planType"],
  data() {
    return {
      popTitle: null,
      popVisible: false,
      sign_stat: 0,
      timer: null, // 签约相关
      loading: null
    };
  },
  watch: {
    item: {
      handler() {
        this.signStat();
      },
      deep: true
    }
  },
  created() {
    // console.log(this.item.trans_stat);
    // 签约状态
    this.signStat();
  },
  mounted() {},
  methods: {
    signStat() {
      if (this.item.tradeOrder && this.item.tradeOrder.state) {
        switch (this.item.tradeOrder.state) {
          case 2:
            this.sign_stat = 1;
            break;
          case 3:
            this.sign_stat = 1;
            break;
          case 4:
            this.sign_stat = 3;
            break;
          case 5:
            this.sign_stat = 4;
            break;
          case 6:
            this.sign_stat = 6;
            break;
          case 11:
            this.sign_stat = 6;
            break;
        }
      }
      // console.log(this.sign_stat);
    },
    refresh() {
      this.$emit("refresh");
    },
    lookContract(item) {
      // 查看合同
      if (item.contract_no) {
        window.open(contractView(item.contract_no));
      }
    },
    downloadContract(item) {
      // 下载合同
      if (item.contract_no) {
        location.href = contractDownload(item.contract_no);
      }
    },
    // 待签约
    gotoSign(item) {
      this.$confirm("确认是否发起签约?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning"
      }).then(() => {
        let obj = {
            seal_type: 1, // 印章类型，0保留，1设计作品版权出售合同章，2商品打样合同章，3代加工合同章(需求定制)
            page: 1,
            pagesize: 15
        }
        if (this.planType == "bq_order") {
          obj.seal_type = 1;
        } else if (this.planType == "dy_order") {
          obj.seal_type = 2;
        } else {
          obj.seal_type = 3;
        }
        listSealMember(obj).then(res=>{
            if (res && res.list && res.list.length>0) {
                this.sealMember = res.list;
                this.selectSign();
            } else {
                this.going(this.item.contract_no);
            }
        }).catch(err=>{
            this.$message.error(err.message);
        });
      });
    },
    selectSign() { // 关闭签约人弹框
        this.popTitle = '请选择签约人';
        this.popVisible = true;
    },
    buy(user_id) { // 选择完签约人回调
      this.going(this.item.contract_no,user_id);
    },
    going(contract_no, user_id) {
      this.loading = Loading.service({
        fullscreen: true,
        text: "拼命加载中....",
        target: this.$parent.$refs.load
      });
      contractAddr(contract_no,user_id)
      .then(rst => {
        console.log(rst);
        if (rst && rst.url) {
          this.sign(rst.url);
          // clearInterval(this.timer);
          // return;
        }
      })
      .catch(e => {
        this.$message.error(e);
        this.loading.close();
        clearInterval(this.timer);
      });
      // this.timer = setInterval(() => {
        
      // }, 2000);
    },
    sign(url) {
      this.loading.close();
      if (url) window.open(url);
      this.signState("签约");
    },
    hide() {
      this.popVisible = false;
      this.$message.success("状态已更新");
    },
    signState(text) {
      this.popTitle = text;
      this.popVisible = true;
    },
    // 查看收款记录
    view_pay(item) {
      this.$router.push({
        path: "/pay/payeeList",
        query: {
          order_id: item.tradeOrder.id
        }
      });
    }
  }
};
</script>
<style scoped>
.signPlan {
  margin-left: 25px;
}
.group-button {
  height: 60px;
  position: relative;
  text-align: center;
  padding: 10px 0;
}
.item-plan >>> .el-step__icon.is-icon,
.el-step__icon {
  width: unset;
}
</style>